import { gapiObject } from "react-gapi";

declare global {
  interface Window {
    gapi: gapiObject;
    tokenClient: any;
  }
}

export const executeGAPI = (callback: () => void) => {
  const gapi = window.gapi;
  const tokenClient = window.tokenClient;

  tokenClient.callback = (resp: { error: undefined }) => {
    if (resp.error !== undefined) {
      throw resp;
    }
    // GIS has automatically updated gapi.client with the newly issued access token.
    console.log(
      "gapi.client access token: " + JSON.stringify(gapi.client.getToken())
    );

    callback();
  };

  // Conditionally ask users to select the Google Account they'd like to use,
  // and explicitly obtain their consent to fetch their Calendar.
  // NOTE: To request an access token a user gesture is necessary.
  if (gapi.client.getToken() === null) {
    // Prompt the user to select a Google Account and asked for consent to share their data
    // when establishing a new session.
    tokenClient.requestAccessToken({ prompt: "" });
  } else {
    // try calling the callback function, if it fails, then prompt the user to select a Google Account
    try {
      callback();
    } catch (e) {
      tokenClient.requestAccessToken({ prompt: "" });
    }
  }
};

// Generated by https://quicktype.io

export interface EventType {
  kind: string;
  etag: string;
  id: string;
  status: string;
  htmlLink: string;
  created: string;
  updated: string;
  summary: string;
  description: string;
  location?: string;
  creator: Creator;
  organizer: Creator;
  start: End;
  end: End;
  iCalUID: string;
  sequence: number;
  reminders: Reminders;
  eventType: string;
  attendees?: Attendee[];
  guestsCanSeeOtherGuests?: boolean;
}

export interface Attendee {
  email: string;
  self: boolean;
  responseStatus: string;
}

export interface Creator {
  email: string;
  self?: boolean;
}

export interface End {
  dateTime: string;
  timeZone: string;
}

export interface Reminders {
  useDefault: boolean;
}
